// Dependencies
import { useState } from 'react';

// Functional Component
export default function ExpandRemovalLinks() {
	// States
	const [isExpanded, setIsExpanded] = useState(false);

	// Return layout
	return (
		<>
			{(isExpanded) ? (
				<>
					<a href="/remove/websites">Websites</a>
					<a href="/remove/social-media-posts">Social Media Posts</a>
					<a href="/remove/blogs">Blogs</a>
					<a href="/remove/fake-accounts">Fake Accounts</a>
					<a href="/remove/leaked-content">Leaked Content</a>
					{/* <a href="/remove/related-searches">Related Searches</a> */}
				</>
			) : (
				<>
					<button onClick={()=>{setIsExpanded(true)}}>{">"} Click for more</button>
				</>
			)}
		</>
	);
}